import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import useI18n from '../utils/useI18n';
import Link from './Link';
import '../styles/components/_components.categories.scss';

const Categories = () => {
  const { t } = useI18n();
  const data = useStaticQuery(graphql`
    query CategoriesQuery {
      categories: allCategory {
        nodes {
          Category {
            id
            slug
            nombre
            totalArticles
          }
        }
      }
    }
  `);
  return (
    <div className="c-categories">
      <span className="u-h3">{t('categories.title')}</span>
      <div className="u-8/12@desktop u-push-2/12@desktop u-margin-top">
        <div className="o-layout">
          {data.categories.nodes.map(({ Category }) => (
            <div className="o-layout__item u-6/12@tablet" key={Category.id}>
              <Link
                className="c-categories__item"
                to={`${t('url.blog')}${t('url.category')}/${Category.slug}`}
              >
                {Category.nombre}
                <span className="c-categories__qty">{Category.totalArticles}</span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
