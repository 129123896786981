import React from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import dayjs from 'dayjs';
import Img from 'gatsby-image';
import get from 'lodash.get';
import { RightCircleOutlined } from '@ant-design/icons';

import Pagination from '../components/Pagination';
import Layout from '../components/Layout';
import SEO from '../components/seo/SEO';
import Hero from '../components/Hero';
import useI18n from '../utils/useI18n';
import ContactButton from '../components/ContactButton';
import Categories from '../components/Categories';
import Content from '../components/Content';
import Link from '../components/Link';

import '../styles/components/_components.post.scss';

loadable(() => import('dayjs/locale/es'));

const Blog = ({ pageContext: { switchLang, categoryIdRegex, tagIdRegex }, data }) => {
  dayjs.locale(get(switchLang, 'lang', 'en'));
  const {
    allArticle: {
      nodes: records,
      pageInfo: { pageCount },
    },
    category,
    tag,
  } = data;
  const { t } = useI18n();
  const baseURL = t('url.blog');
  const baseURLCategory = `${t('url.blog')}/${t('url.category')}/${get(category, 'Category.slug')}`;
  const baseURLTag = `${t('url.blog')}/${t('url.tag')}/${get(tag, 'Tag.slug')}`;

  return (
    <Layout switchLang={switchLang}>
      <SEO
        title={t('blog.meta.title')}
        description={t('blog.meta.description')}
        keywords={t('blog.meta.keywords')}
        pathname={baseURL}
        webpage
      />
      {!categoryIdRegex && !tagIdRegex ? (
        <Hero
          title={t('blog.hero.title')}
          callout={t('blog.hero.callout')}
          secondary
          extra={
            <p
              dangerouslySetInnerHTML={{
                __html: t('blog.hero.extra', { url: t('url.tips') }),
              }}
            />
          }
        />
      ) : null}
      {categoryIdRegex && (
        <Hero
          title={category.Category.nombre}
          secondary
          callout={t('blog.hero.callout.category')}
        />
      )}
      {tagIdRegex && <Hero title={tag.Tag.nombre} secondary callout={t('blog.hero.callout.tag')} />}

      {records.map((record) => {
        const { Article, featuredImg, Category } = record;
        return (
          <article
            key={Article.id}
            style={{ marginBottom: 64 }}
            className="c-section c-section--separator c-section--small"
          >
            <div className="o-wrapper u-10/12@desktop u1/12@desktop c-post c-post--preview">
              <Link to={`${baseURL}/${Article.id}-${Article.slug}/`} className="c-post__link" />
              <div className="c-post__content">
                <h2 className="c-post__title">
                  <Link to={`${baseURL}/${Article.id}-${Article.slug}/`}>{Article.titulo}</Link>
                </h2>
                <span className="c-post__meta">
                  {[
                    get(Category, 'nombre'),
                    dayjs(Article.created).format('DD MMM YYYY'),
                    t('commentsTotal', { count: Article.comments }, false),
                  ].join(' | ')}
                </span>
                {featuredImg && (
                  <Img
                    className="c-post__img"
                    fluid={featuredImg.childImageSharp.fluid}
                    fadeIn={false}
                  />
                )}
                <div className="u-align-center u-10/12@desktop u-push-1/12@desktop">
                  <Content
                    html={Article.contenido
                      .replace(/text-align: (left|right|center);/, '')
                      .replace(/style=""/, '')}
                    limit={400}
                  />
                </div>
              </div>
              <Link to={`${baseURL}/${Article.id}-${Article.slug}/`} className="c-button">
                {t('blog.article.readMore')} <RightCircleOutlined />
              </Link>
            </div>
          </article>
        );
      })}
      <div className="u-align-center">
        <Pagination
          totalPages={pageCount}
          baseUrl={(() => {
            if (categoryIdRegex) return baseURLCategory;
            if (tagIdRegex) return baseURLTag;
            return baseURL;
          })()}
        />
      </div>
      <Categories />
      <ContactButton section />
    </Layout>
  );
};

export const query = graphql`
  query BlogPageQuery(
    $skip: Int!
    $limit: Int!
    $categoryIdRegex: String = "/.+/"
    $tagIdRegex: String = "/.+/"
  ) {
    allArticle(
      skip: $skip
      limit: $limit
      filter: {
        Category: { id: { regex: $categoryIdRegex } }
        Tag: { elemMatch: { id: { regex: $tagIdRegex } } }
      }
      sort: { order: DESC, fields: Article___created }
    ) {
      nodes {
        Article {
          id
          created
          slug
          titulo
          contenido
          rating_value
          comments
        }
        childHtmlRehype {
          htmlAst
          html
        }
        Category {
          id
          nombre
        }
        featuredImg {
          childImageSharp {
            fluid(maxWidth: 900, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      pageInfo {
        pageCount
      }
    }
    category(Category: { id: { regex: $categoryIdRegex } }) {
      Category {
        id
        nombre
        slug
      }
    }
    tag(Tag: { id: { regex: $tagIdRegex } }) {
      Tag {
        id
        nombre
        slug
      }
    }
  }
`;

export default Blog;
